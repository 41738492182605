import React, { useEffect } from 'react';

import './Home.scss';

const Home: React.FC = () => {
  useEffect(() => {}, []);
  return (
    <div className="home">
      <div>Home</div>
    </div>
  );
};

export default Home;

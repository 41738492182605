import { Col, message, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

import Button from '~/components/Button/Button';
import Card from '~/components/Card/Card';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ChurchsService } from '~/services/ChurchsService';
import { Church as ChurchEntitie } from '~/entities/church';
import Loader from '~/components/Loader/Loader';
import Image from '~/components/Image/Image';
import { DATE_FORMAT, formatDate } from '~/utils/date';
import TextField from '~/components/fields/TextField/TextField';
import MessageField from '~/components/fields/MessageField/MessageField';
import UploadField from '~/components/fields/UploadField/UploadField';
import { CREATE_CHURCH_ROUTE } from '~/routing/routes';

import './Church.scss';

const Church: React.FC = () => {
  const [churchs, setChurchs] = useState<ChurchEntitie[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isViewChurchModalVisible, setViewChurchModalVisible] = useState(false);
  const [isUpdateChurchModalVisible, setUpdateChurchModalVisible] = useState(false);
  const [updatingChurchInfos, setUpdatingChurchInfos] = useState(false);

  const [church, setChurch] = useState<ChurchEntitie>();
  const [gettingOnlyChurch, setGettingOnlyChurch] = useState(false);

  const validateUpdateChurch = Yup.object({
    latitude: Yup.number().required('La latitude est requise'),
    longitude: Yup.number().required('La longitude est requise'),
  });

  const { push: redirect } = useHistory();

  const getChurchs = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);

      const res = await ChurchsService.getChurchs();
      if (res.success === true) {
        const finalChurchs: ChurchEntitie[] = [];
        res.churchs.map((e, i) => {
          const alteredChurch = { ...e, customId: i + 1 };
          finalChurchs.push(alteredChurch);
        });
        setLoading(false);
        setChurchs(finalChurchs);
      } else {
        setLoading(false);
        setChurchs([]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error('Impossible de récupérer la liste des utilisateurs', 3);
    }
  }, []);

  const getOnlyChurch = useCallback(async (id: any) => {
    setGettingOnlyChurch(true);
    try {
      const res = await ChurchsService.getChurch(id);
      console.log('user', res);
      if (res.success === true) {
        setChurch(res);
        setGettingOnlyChurch(false);
      }
    } catch (error: any) {
      setGettingOnlyChurch(false);
      message.error("Impossible d'obtenir les informations de l'église", 3);
    }
  }, []);

  const deleteChurch = useCallback(async (id: any) => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        key: loadingKey,
        content: 'Suppression en cours',
      });
      const res = await ChurchsService.deleteChurch(id);
      console.log('status', res);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success("L'église a bien été supprimée");
        await getChurchs();
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error("Impossible de supprimer l'église");
    }
  }, []);

  const handleUpdateChurch = useCallback(async (newChurch: ChurchEntitie, formValues) => {
    setUpdatingChurchInfos(true);
    const loadingKey = 'loadingMessage';
    try {
      message.loading({
        content: 'Mise à jour en cours',
        key: loadingKey,
      });
      const res = await ChurchsService.putChurch(newChurch._id as string, {
        ...formValues,
        location: {
          coordinates: [formValues.longitude, formValues.latitude],
          type: newChurch.location?.type,
        },
      });
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success('Les informations de ' + formValues.nom + ' ont bien été actualisées', 5);
        setUpdatingChurchInfos(false);
        setUpdateChurchModalVisible(false);
        getChurchs();
      }
    } catch (error: any) {
      setUpdatingChurchInfos(false);
      message.destroy(loadingKey);
      message.error("Impossible de mettre à jour les infos de l'église", 3);
    }
  }, []);

  const showViewChurchModal = useCallback(async (id: any) => {
    setViewChurchModalVisible(true);
    await getOnlyChurch(id);
  }, []);

  const handleOkViewChurchModal = useCallback(() => {
    setViewChurchModalVisible(false);
  }, []);

  const handleCancelViewChurchModal = useCallback(() => {
    setViewChurchModalVisible(false);
  }, []);

  const showUpdateChurchModal = useCallback(async (id: any) => {
    setUpdateChurchModalVisible(true);
    await getOnlyChurch(id);
  }, []);

  const handleOkUpdateChurchModal = useCallback(() => {
    setUpdateChurchModalVisible(false);
  }, []);

  const handleCancelUpdateChurchModal = useCallback(() => {
    setUpdateChurchModalVisible(false);
  }, []);

  const confirmDeleteChurchModal = useCallback((messageText: string, id: any) => {
    Modal.confirm({
      content: <div className="tac">{messageText}</div>,
      onOk: async () => await deleteChurch(id),
      okText: 'Oui',
      cancelText: 'Annuler',
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'customId',
        sorter: true,
        key: 'customId',
        width: '1%',
      },
      {
        title: 'Nom',
        dataIndex: 'nom',
        sorter: true,
        key: 'nom',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        key: 'description',
      },
      {
        title: 'Ville',
        dataIndex: 'ville',
        sorter: true,
        key: 'ville',
      },
      {
        title: 'Commune',
        dataIndex: 'commune',
        sorter: true,
        key: 'commune',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        sorter: true,
        key: 'image',
        render: (image: string) => (
          <Row justify="center">
            <Image image={image} height={50} width={50} />
          </Row>
        ),
      },
      {
        title: 'Actions',
        children: [
          {
            title: 'Voir',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Afficher l'église">
                <Button
                  onClick={() => showViewChurchModal(id)}
                  title=""
                  color="success"
                  size="s"
                  rounded
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Supp',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Supprimer une église">
                <Button
                  onClick={() => confirmDeleteChurchModal('Supprimer cette église ?', id)}
                  title="x"
                  color="danger"
                  size="s"
                  rounded
                />
              </Tooltip>
            ),
          },
          {
            title: 'Up',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Modifier une église">
                <Button
                  onClick={() => showUpdateChurchModal(id)}
                  title=""
                  color="warning"
                  size="s"
                  rounded
                  icon={<EditOutlined />}
                />
              </Tooltip>
            ),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getChurchs();
  }, []);
  return (
    <div className="church">
      <Tooltip className="mb-5" title="Cliquez ici pour créer une nouvele église">
        <Button
          title="Créer une nouvelle église"
          color="bluedark"
          rounded
          size="s"
          icon={<PlusOutlined className="mt-1 mr-1" />}
          onClick={() => redirect(CREATE_CHURCH_ROUTE())}
        />
      </Tooltip>
      {loading ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="church-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Modal
            title={gettingOnlyChurch ? <Loader /> : <div>{`Détails de l'église ${church?.nom}`}</div>}
            visible={isViewChurchModalVisible}
            onOk={() => {
              handleOkViewChurchModal();
            }}
            onCancel={handleCancelViewChurchModal}
          >
            {gettingOnlyChurch ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <>
                <Title level={4} weight={FontWeight.MEDIUM} className="tac">
                  Informations sur l'église
                </Title>
                <Row justify="center" className="mb-5">
                  <Image image={church?.image} rounded height={100} width={100} />
                </Row>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Dernière connexion: </Text>
                  <Text>
                    {church?.lastLogin !== null
                      ? formatDate(church?.lastLogin as Date, DATE_FORMAT.DATE_TIME)
                      : 'Jamais'}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Nom de l'église: </Text>
                  <Text>{church?.nom}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Description: </Text>
                  <Text>{church?.description}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Pays: </Text>
                  <Text>{church?.pays}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Ville: </Text>
                  <Text>{church?.ville}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Commune: </Text>
                  <Text>{church?.commune}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Email: </Text>
                  <Text>{church?.email ?? ''}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Identifiant: </Text>
                  <Text>{church?.identifiant ?? ''}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Longitude: </Text>
                  <Text>{church?.location?.coordinates[0] ?? ''}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Latitude: </Text>
                  <Text>{church?.location?.coordinates[1] ?? ''}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date d'insertion: </Text>
                  <Text>{formatDate(church?.createdAt as Date, DATE_FORMAT.DATE_TIME)}</Text>
                </Paragraph>
              </>
            )}
          </Modal>
          <Modal
            footer
            title={gettingOnlyChurch ? <Loader /> : <div>{`Modifier les informations de l'église ${church?.nom}`}</div>}
            visible={isUpdateChurchModalVisible}
            onOk={() => {
              handleOkUpdateChurchModal();
            }}
            onCancel={handleCancelUpdateChurchModal}
          >
            {gettingOnlyChurch ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <Formik
                initialValues={{
                  nom: church?.nom,
                  description: church?.description,
                  ville: church?.ville,
                  commune: church?.commune,
                  image: church?.image,
                  pays: church?.pays,
                  email: church?.email,
                  identifiant: church?.identifiant,
                  longitude: church?.location?.coordinates[0],
                  latitude: church?.location?.coordinates[1],
                }}
                enableReinitialize
                validationSchema={validateUpdateChurch}
                onSubmit={values => handleUpdateChurch(church as ChurchEntitie, values)}
              >
                {formik => (
                  <Form>
                    {console.log(formik.values)}

                    <Row justify="center">
                      <UploadField formProps={formik} name="image" defaultAvatar={church?.image} title="" />
                    </Row>

                    <TextField
                      disabled={updatingChurchInfos}
                      name="nom"
                      height={35}
                      label="Nom de l'église"
                      type="text"
                      placeholder="Nom de l'église"
                      className="mb-3"
                    />
                    <MessageField
                      disabled={updatingChurchInfos}
                      name="description"
                      label="Description"
                      placeholder="Description"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="pays"
                      height={35}
                      label="Pays"
                      type="text"
                      placeholder="Pays"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="email"
                      height={35}
                      label="Email"
                      type="email"
                      placeholder="Email"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="identifiant"
                      height={35}
                      label="Identifiant"
                      type="text"
                      placeholder="Identifiant"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="longitude"
                      height={35}
                      label="Longitude"
                      type="text"
                      placeholder="Longitude"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="latitude"
                      height={35}
                      label="Latitude"
                      type="text"
                      placeholder="Latitude"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="ville"
                      height={35}
                      label="Ville"
                      type="text"
                      placeholder="Ville"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingChurchInfos}
                      name="commune"
                      height={35}
                      label="Commune"
                      type="text"
                      placeholder="Commune"
                    />

                    <Row justify="center" className="mt-7">
                      <Button
                        onClick={() => handleCancelUpdateChurchModal()}
                        disabled={updatingChurchInfos}
                        type="button"
                        rounded
                        color="light"
                        title="Annuler"
                        size="m"
                      />
                      <Tooltip title="Cliquez sur le bouton pour modifier les informations de l'église">
                        <Button
                          disabled={updatingChurchInfos}
                          type="submit"
                          rounded
                          color="success"
                          title="Enregistrer"
                          size="m"
                        />
                      </Tooltip>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </Modal>
          <Card slightlyRounded className="church-body-content">
            <Row>
              <Col lg={8}>
                <Title level={4} weight={FontWeight.SEMIBOLD}>
                  Églises
                </Title>
              </Col>
              <Col lg={3} offset={13} className="tar">
                <Tooltip title="Cliquez pour actualiser la liste">
                  <Button
                    onClick={() => getChurchs()}
                    title="Actualiser"
                    color="success"
                    size="s"
                    rounded
                    icon={<SyncOutlined className="mr-1 mt-1" />}
                  />
                </Tooltip>
              </Col>
            </Row>

            <div className="churchTableWrapper">
              <Table bordered size="middle" columns={columns} dataSource={churchs} pagination={{ pageSize: 30 }} />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default Church;

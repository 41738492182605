import React, { HTMLProps } from 'react';
import { ErrorMessage, useField } from 'formik';
import cn from 'classnames';

import './MessageField.scss';

interface MessageFieldProps extends Omit<HTMLProps<HTMLTextAreaElement>, 'label' | 'name'> {
  label?: string;
  name: string;
  labelClassName?: string;
}

const MessageField: React.FC<MessageFieldProps> = ({
  placeholder,
  disabled,
  label,
  className,
  name,
  height,
  labelClassName,
  ...restParams
}) => {
  const [field, meta] = useField(name);

  return (
    <div className={cn(className, 'message-field')}>
      {label && (
        <label htmlFor={field.name} className={cn(labelClassName, 'message-field-label')}>
          {label}
        </label>
      )}
      <textarea
        className={cn({ 'form-control': meta.touched && meta.error }, { 'is-invalid': meta.touched && meta.error })}
        {...restParams}
        {...field}
        style={{ height }}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};
export default MessageField;

import cn from 'classnames';
import React, { HTMLProps, ReactElement } from 'react';

import Image from '~/components/Image/Image';

import './Button.scss';

export type ColorType =
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-primary'
  | 'outline-secondary'
  | 'light'
  | 'secondary'
  | 'warning'
  | 'blue-facebook'
  | 'success'
  | 'orange'
  | 'danger'
  | 'info'
  | 'bluedark';

export type IconType = string | ReactElement;
export interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size' | 'color'> {
  onClick?: () => void;
  title: string;
  size?: 's' | 'm' | 'l'; // add sizes if needed
  type?: 'button' | 'submit';
  color?: ColorType;
  icon?: IconType;
  rounded?: boolean;
  circle?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  rounded,
  circle,
  style,
  size = 'm',
  color,
  icon,
  className,
  disabled,
  ...props
}) => (
  <button
    className={cn(
      className,
      'button',
      `button-${size}`,
      color && `button-${color}`,
      { circle },
      { rounded },
      { disabled },
    )}
    disabled={disabled}
    onClick={onClick}
    style={style}
    {...props}
  >
    {icon && (typeof icon === 'string' ? <Image className="button-icon" image={icon} width={20} height={20} /> : icon)}
    {title}
  </button>
);

export default Button;

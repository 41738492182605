/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext } from 'react';
import { Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useIdleTimer } from 'react-idle-timer';

import NavigationBar, { NavigationBarProps } from '~/containers/Header/NavigationBar/NavigationBar';
import { SessionsService } from '~/services/SessionsService';
import { LoadingContext } from '~/contexts/LoaderContext';

import HeaderTitle from './HeaderTitle/HeaderTitle';

import './Header.scss';

interface HeaderProps extends NavigationBarProps {
  onClick?: () => void;
  showSidebar?: boolean;
}
const Header: React.FC<HeaderProps> = ({ items, onClick, showSidebar }) => {
  const { startLoading } = useContext(LoadingContext);
  const onIdle = useCallback(async () => {
    // startLoading();
    // await SessionsService.logout();
  }, []);

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onIdle,
    timeout: 5 * 60000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <div className="header">
      <Row>
        <Col span={8}>
          <div className="header-layer">
            {showSidebar ? (
              <LeftOutlined onClick={onClick} className="header-left-btn-icon" />
            ) : (
              <RightOutlined onClick={onClick} className="header-left-btn-icon" />
            )}

            <HeaderTitle />
          </div>
        </Col>
        <Col span={8} offset={8}>
          <NavigationBar items={items} />
        </Col>
      </Row>
    </div>
  );
};

export default Header;

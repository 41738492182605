import { Priere } from '~/entities/priere';
import GeneralResponse from '~/entities/responses/generalResponse';
import GetPrieresResponse from '~/entities/responses/getPrieresResponse';
import { APIService } from '~/services/ApiService';

export const PrieresService = {
  async getPrieres(): Promise<GetPrieresResponse> {
    const res = await APIService.call('GET', '/admin/prieres/intentions');
    return res.data;
  },
  async getPriere(id: string): Promise<Priere> {
    const res = await APIService.call('GET', `/admin/prieres/intentions/${id}`);
    return res.data;
  },
  async deletePriere(id: string): Promise<GeneralResponse> {
    const res = await APIService.call('DELETE', `/admin/prieres/intentions/${id}`);
    return res.data;
  },
};

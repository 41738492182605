import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import LoginForm from '~/components/forms/LoginForm/LoginForm';
import { SessionsService } from '~/services/SessionsService';

import './Login.scss';

const Login: React.FC = () => {
  const { push: redirect } = useHistory();
  const handleLogin = useCallback(async formValues => {
    const loadingMessageKey = 'loadingMessage';
    message.loading({
      key: loadingMessageKey,
      content: 'Chargement',
    });
    try {
      await SessionsService.login(formValues.identifiant, formValues.password);

      message.destroy(loadingMessageKey);
      redirect('/');
    } catch (error: any) {
      console.log('login', error);
      message.destroy(loadingMessageKey);
      message.error('Identifiant ou mot de passe incorrecte', 4);
    }
  }, []);
  return (
    <div className="login">
      <LoginForm handleSubmit={handleLogin} />
    </div>
  );
};

export default Login;

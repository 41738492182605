import GeneralResponse from '~/entities/responses/generalResponse';
import GetVideosResponse from '~/entities/responses/getVideosResponse';
import { Video } from '~/entities/video';
import { APIService } from '~/services/ApiService';

interface CreateVideoBody {
  titre: string;
  description: string;
  url: string;
}

export const VideoService = {
  async getVideos(): Promise<GetVideosResponse> {
    const res = await APIService.call('GET', '/admin/videos');
    return res.data;
  },
  async getVideo(id: string): Promise<Video> {
    const res = await APIService.call('GET', `/admin/videos/${id}`);
    return res.data;
  },
  async deleteVideo(id: string): Promise<GeneralResponse> {
    const res = await APIService.call('DELETE', `/admin/videos/${id}`);
    return res.data;
  },
  async createVideo(body: CreateVideoBody): Promise<GeneralResponse> {
    const res = await APIService.call('POST', `/admin/videos`, body);
    return res.data;
  },
  async putVideo(id: string, body: CreateVideoBody): Promise<GeneralResponse> {
    const res = await APIService.call('PUT', `/admin/videos/${id}`, body);
    return res.data;
  },
};

import { Col, message, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

import Button from '~/components/Button/Button';
import Card from '~/components/Card/Card';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { ActivitesService } from '~/services/ActivitesService';
import { Activite as ActiviteEntitie } from '~/entities/activite';
import Loader from '~/components/Loader/Loader';
import Image from '~/components/Image/Image';
import { DATE_FORMAT, formatDate } from '~/utils/date';
import TextField from '~/components/fields/TextField/TextField';
import MessageField from '~/components/fields/MessageField/MessageField';
import UploadField from '~/components/fields/UploadField/UploadField';
import { CREATE_ACTIVITE_ROUTE } from '~/routing/routes';

import './Activite.scss';

const Activite: React.FC = () => {
  const [activites, setActivites] = useState<ActiviteEntitie[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isViewActiviteModalVisible, setViewActiviteModalVisible] = useState(false);
  const [isUpdateActiviteModalVisible, setUpdateActiviteModalVisible] = useState(false);
  const [updatingActiviteInfos, setUpdatingActiviteInfos] = useState(false);

  const [activite, setActivite] = useState<ActiviteEntitie>();
  const [gettingOnlyActivite, setGettingOnlyActivite] = useState(false);

  const validateUpdateActivite = Yup.object({});

  const { push: redirect } = useHistory();

  const getActivites = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);

      const res = await ActivitesService.getActivites();
      if (res.success === true) {
        const finalActivites: ActiviteEntitie[] = [];
        res.activites.map((e, i) => {
          const alteredActivite = { ...e, customId: i + 1 };
          finalActivites.push(alteredActivite);
        });
        setLoading(false);
        setActivites(finalActivites);
      } else {
        setLoading(false);
        setActivites([]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error('Impossible de récupérer la liste des utilisateurs', 3);
    }
  }, []);

  const getOnlyActivite = useCallback(async (id: any) => {
    setGettingOnlyActivite(true);
    try {
      const res = await ActivitesService.getActivite(id);
      if (res.success === true) {
        setActivite(res);
        setGettingOnlyActivite(false);
      }
    } catch (error: any) {
      setGettingOnlyActivite(false);
      message.error("Impossible d'obtenir les informations de l'activité", 3);
    }
  }, []);

  const deleteActivite = useCallback(async (id: any) => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        key: loadingKey,
        content: 'Suppression en cours',
      });
      const res = await ActivitesService.deleteActivite(id);
      console.log('status', res);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success("L'activité a bien été supprimée");
        await getActivites();
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error("Impossible de supprimer l'activité");
    }
  }, []);

  const handleUpdateActivite = useCallback(async (newActivite: ActiviteEntitie, formValues) => {
    setUpdatingActiviteInfos(true);
    const loadingKey = 'loadingMessage';
    try {
      message.loading({
        content: 'Mise à jour en cours',
        key: loadingKey,
      });
      const res = await ActivitesService.putActivite(newActivite._id as string, formValues);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success('Les informations de ' + formValues.titre + ' ont bien été actualisées', 5);
        setUpdatingActiviteInfos(false);
        setUpdateActiviteModalVisible(false);
        getActivites();
      }
    } catch (error: any) {
      setUpdatingActiviteInfos(false);
      message.destroy(loadingKey);
      message.error("Impossible de mettre à jour les infos de l'activité", 3);
    }
  }, []);

  const showViewActiviteModal = useCallback(async (id: any) => {
    setViewActiviteModalVisible(true);
    await getOnlyActivite(id);
  }, []);

  const handleOkViewActiviteModal = useCallback(() => {
    setViewActiviteModalVisible(false);
  }, []);

  const handleCancelViewActiviteModal = useCallback(() => {
    setViewActiviteModalVisible(false);
  }, []);

  const showUpdateActiviteModal = useCallback(async (id: any) => {
    setUpdateActiviteModalVisible(true);
    await getOnlyActivite(id);
  }, []);

  const handleOkUpdateActiviteModal = useCallback(() => {
    setUpdateActiviteModalVisible(false);
  }, []);

  const handleCancelUpdateActiviteModal = useCallback(() => {
    setUpdateActiviteModalVisible(false);
  }, []);

  const confirmDeleteActiviteModal = useCallback((messageText: string, id: any) => {
    Modal.confirm({
      content: <div className="tac">{messageText}</div>,
      onOk: async () => await deleteActivite(id),
      okText: 'Oui',
      cancelText: 'Annuler',
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'customId',
        sorter: true,
        key: 'customId',
        width: '1%',
      },
      {
        title: 'Nom',
        dataIndex: 'titre',
        sorter: true,
        key: 'titre',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        key: 'description',
      },
      {
        title: 'Ville',
        dataIndex: 'ville',
        sorter: true,
        key: 'ville',
      },
      {
        title: 'Commune',
        dataIndex: 'commune',
        sorter: true,
        key: 'commune',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        sorter: true,
        key: 'image',
        render: (image: string) => (
          <Row justify="center">
            <Image image={image} height={50} width={50} />
          </Row>
        ),
      },
      {
        title: 'Actions',
        children: [
          {
            title: 'Voir',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Afficher l'activité">
                <Button
                  onClick={() => showViewActiviteModal(id)}
                  title=""
                  color="success"
                  size="s"
                  rounded
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Supp',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Supprimer une activité">
                <Button
                  onClick={() => confirmDeleteActiviteModal('Supprimer cette activité ?', id)}
                  title="x"
                  color="danger"
                  size="s"
                  rounded
                />
              </Tooltip>
            ),
          },
          {
            title: 'Up',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Modifier une activité">
                <Button
                  onClick={() => showUpdateActiviteModal(id)}
                  title=""
                  color="warning"
                  size="s"
                  rounded
                  icon={<EditOutlined />}
                />
              </Tooltip>
            ),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getActivites();
  }, []);
  return (
    <div className="activite">
      <Tooltip className="mb-5" title="Cliquez ici pour créer une nouvele activité">
        <Button
          title="Créer une nouvelle activité"
          color="bluedark"
          rounded
          size="s"
          icon={<PlusOutlined className="mt-1 mr-1" />}
          onClick={() => redirect(CREATE_ACTIVITE_ROUTE())}
        />
      </Tooltip>
      {loading ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="activite-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Modal
            title={gettingOnlyActivite ? <Loader /> : <div>{`Détails de l'activité ${activite?.titre}`}</div>}
            visible={isViewActiviteModalVisible}
            onOk={() => {
              handleOkViewActiviteModal();
            }}
            onCancel={handleCancelViewActiviteModal}
          >
            {gettingOnlyActivite ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <>
                <Title level={4} weight={FontWeight.MEDIUM} className="tac">
                  Informations sur l'activité
                </Title>
                <Row justify="center" className="mb-5">
                  <Image image={activite?.image} rounded height={100} width={100} />
                </Row>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Nom de l'activité: </Text>
                  <Text>{activite?.titre}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Description: </Text>
                  <Text>{activite?.description}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Ville: </Text>
                  <Text>{activite?.ville}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Commune: </Text>
                  <Text>{activite?.commune}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date de l'activité: </Text>
                  <Text>{formatDate(activite?.date as Date, DATE_FORMAT.DATE)}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date d'insertion: </Text>
                  <Text>{formatDate(activite?.createdAt as Date, DATE_FORMAT.DATE_TIME)}</Text>
                </Paragraph>
              </>
            )}
          </Modal>
          <Modal
            footer
            title={
              gettingOnlyActivite ? <Loader /> : <div>{`Modifier les informations de l'église ${activite?.titre}`}</div>
            }
            visible={isUpdateActiviteModalVisible}
            onOk={() => {
              handleOkUpdateActiviteModal();
            }}
            onCancel={handleCancelUpdateActiviteModal}
          >
            {gettingOnlyActivite ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <Formik
                initialValues={{
                  titre: activite?.titre,
                  description: activite?.description,
                  ville: activite?.ville,
                  commune: activite?.commune,
                  image: activite?.image,
                  date: activite?.date,
                }}
                enableReinitialize
                validationSchema={validateUpdateActivite}
                onSubmit={values => handleUpdateActivite(activite as ActiviteEntitie, values)}
              >
                {formik => (
                  <Form>
                    {console.log(formik.values)}

                    <Row justify="center">
                      <UploadField formProps={formik} name="image" defaultAvatar={activite?.image} title="" />
                    </Row>

                    <TextField
                      disabled={updatingActiviteInfos}
                      name="titre"
                      height={35}
                      label="Nom de l'activité"
                      type="text"
                      placeholder="Nom de l'activité"
                      className="mb-3"
                    />
                    <MessageField
                      disabled={updatingActiviteInfos}
                      name="description"
                      label="Description"
                      placeholder="Description"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingActiviteInfos}
                      name="ville"
                      height={35}
                      label="Ville"
                      type="text"
                      placeholder="Ville"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingActiviteInfos}
                      name="commune"
                      height={35}
                      label="Commune"
                      type="text"
                      placeholder="Commune"
                      className="mb-3"
                    />

                    <TextField
                      disabled={updatingActiviteInfos}
                      name="date"
                      height={35}
                      label={`Date de l'activité (${formatDate(activite?.date as Date, DATE_FORMAT.DATE)})`}
                      type="date"
                      placeholder="Date de l'activité"
                    />

                    <Row justify="center" className="mt-7">
                      <Button
                        onClick={() => handleCancelUpdateActiviteModal()}
                        disabled={updatingActiviteInfos}
                        type="button"
                        rounded
                        color="light"
                        title="Annuler"
                        size="m"
                      />
                      <Tooltip title="Cliquez sur le bouton pour modifier les informations de l'activité">
                        <Button
                          disabled={updatingActiviteInfos}
                          type="submit"
                          rounded
                          color="success"
                          title="Enregistrer"
                          size="m"
                        />
                      </Tooltip>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </Modal>
          <Card slightlyRounded className="activite-body-content">
            <Row>
              <Col lg={8}>
                <Title level={4} weight={FontWeight.SEMIBOLD}>
                  Activités
                </Title>
              </Col>
              <Col lg={3} offset={13} className="tar">
                <Tooltip title="Cliquez pour actualiser la liste">
                  <Button
                    onClick={() => getActivites()}
                    title="Actualiser"
                    color="success"
                    size="s"
                    rounded
                    icon={<SyncOutlined className="mr-1 mt-1" />}
                  />
                </Tooltip>
              </Col>
            </Row>

            <div className="activiteTableWrapper">
              <Table bordered size="middle" columns={columns} dataSource={activites} pagination={{ pageSize: 30 }} />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default Activite;

import { Col, message, Modal, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Title } from '~/components/Typo/Typo';
import Card from '~/components/Card/Card';
import { FontWeight } from '~/theme/constants';
import Loader from '~/components/Loader/Loader';
import Button from '~/components/Button/Button';
import { VideoService } from '~/services/VideoService';
import CreateVideoForm from '~/components/forms/CreateVideoForm/CreateVideoForm';

import './CreateVideo.scss';

const CreateVideo: React.FC = () => {
  const { goBack } = useHistory();

  const [creatingVideo, setCreatingVideo] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Ajout de la vidéo en cours...');

  const successModal = useCallback((messageText: string) => {
    const seconds = 3;
    const modal = Modal.success({
      content: <div className="tac">{messageText}</div>,
    });
    setTimeout(() => {
      modal.destroy();
    }, seconds * 1000);
  }, []);

  const handleCreateVideo = useCallback(async formValues => {
    setCreatingVideo(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);
      const res = await VideoService.createVideo(formValues);
      if (res.success === true) {
        setCreatingVideo(false);
        successModal('La vidéo a bien été ajoutée');
        setTimeout(() => {
          goBack();
        }, 3000);
      } else {
        setCreatingVideo(false);
      }
    } catch (error: any) {
      setCreatingVideo(false);
      message.error(error.response?.data?.statusText || "Impossible d'ajouter la vidéo");
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="createVideo">
      <div className="createVideo-body-header">
        <Button
          onClick={() => goBack()}
          title="Retour"
          size="s"
          rounded
          color="bluedark"
          type="button"
          icon={<ArrowLeftOutlined className="mt-1 mr-1" />}
          className="mr-3"
        />
      </div>
      {creatingVideo ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="createVideo-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Col lg={15}>
            <Card slightlyRounded className="createVideo-body-content">
              <div className="createVideoTableWrapper">
                <Title level={3} weight={FontWeight.SEMIBOLD}>
                  Ajouter une vidéo
                </Title>
                <Row justify="center">
                  <Col lg={20}>
                    <CreateVideoForm handleSubmit={handleCreateVideo} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CreateVideo;

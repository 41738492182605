import React from 'react';

import Router from '~/routing/Router';

import 'moment/locale/fr';

function App() {
  return <Router />;
}

export default App;

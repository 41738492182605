import { Col, message, Modal, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Title } from '~/components/Typo/Typo';
import Card from '~/components/Card/Card';
import { FontWeight } from '~/theme/constants';
import Loader from '~/components/Loader/Loader';
import Button from '~/components/Button/Button';
import { ChurchsService } from '~/services/ChurchsService';
import CreateChurchForm from '~/components/forms/CreateChurchForm/CreateChurchForm';

import './CreateChurch.scss';

const CreateChurch: React.FC = () => {
  const { goBack } = useHistory();

  const [creatingChurch, setCreatingChurch] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Création de l'église en cours...");

  const successModal = useCallback((messageText: string) => {
    const seconds = 3;
    const modal = Modal.success({
      content: <div className="tac">{messageText}</div>,
    });
    setTimeout(() => {
      modal.destroy();
    }, seconds * 1000);
  }, []);

  const handleCreateChurch = useCallback(async formValues => {
    setCreatingChurch(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);
      const res = await ChurchsService.createChurch({
        ...formValues,
        location: {
          coordinates: [formValues.longitude, formValues.latitude],
          type: 'Point',
        },
      });
      if (res.success === true) {
        setCreatingChurch(false);
        successModal("L'église a bien été créée");
        setTimeout(() => {
          goBack();
        }, 3000);
      } else {
        setCreatingChurch(false);
      }
    } catch (error: any) {
      setCreatingChurch(false);
      message.error(error.response?.data?.statusText || "Impossible de créer l'église");
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="createChurch">
      <div className="createChurch-body-header">
        <Button
          onClick={() => goBack()}
          title="Retour"
          size="s"
          rounded
          color="bluedark"
          type="button"
          icon={<ArrowLeftOutlined className="mt-1 mr-1" />}
          className="mr-3"
        />
      </div>
      {creatingChurch ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="createChurch-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Col lg={15}>
            <Card slightlyRounded className="createChurch-body-content">
              <div className="createChurchTableWrapper">
                <Title level={3} weight={FontWeight.SEMIBOLD}>
                  Créer une église
                </Title>
                <Row justify="center">
                  <Col lg={20}>
                    <CreateChurchForm handleSubmit={handleCreateChurch} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CreateChurch;

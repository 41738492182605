import { Col, message, Modal, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Title } from '~/components/Typo/Typo';
import Card from '~/components/Card/Card';
import { FontWeight } from '~/theme/constants';
import Loader from '~/components/Loader/Loader';
import Button from '~/components/Button/Button';
import { ActivitesService } from '~/services/ActivitesService';
import CreateActiviteForm from '~/components/forms/CreateActiviteForm/CreateActiviteForm';

import './CreateActivite.scss';

const CreateActivite: React.FC = () => {
  const { goBack } = useHistory();

  const [creatingActivite, setCreatingActivite] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Création de l'activité en cours...");

  const successModal = useCallback((messageText: string) => {
    const seconds = 3;
    const modal = Modal.success({
      content: <div className="tac">{messageText}</div>,
    });
    setTimeout(() => {
      modal.destroy();
    }, seconds * 1000);
  }, []);

  const handleCreateActivite = useCallback(async formValues => {
    setCreatingActivite(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);
      const res = await ActivitesService.createActivite(formValues);
      if (res.success === true) {
        setCreatingActivite(false);
        successModal("L'activité a bien été créée");
        setTimeout(() => {
          goBack();
        }, 3000);
      } else {
        setCreatingActivite(false);
      }
    } catch (error: any) {
      setCreatingActivite(false);
      message.error(error.response?.data?.statusText || "Impossible de créer l'activité");
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="createActivite">
      <div className="createActivite-body-header">
        <Button
          onClick={() => goBack()}
          title="Retour"
          size="s"
          rounded
          color="bluedark"
          type="button"
          icon={<ArrowLeftOutlined className="mt-1 mr-1" />}
          className="mr-3"
        />
      </div>
      {creatingActivite ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="createActivite-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Col lg={15}>
            <Card slightlyRounded className="createActivite-body-content">
              <div className="createActiviteTableWrapper">
                <Title level={3} weight={FontWeight.SEMIBOLD}>
                  Créer une activité
                </Title>
                <Row justify="center">
                  <Col lg={20}>
                    <CreateActiviteForm handleSubmit={handleCreateActivite} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CreateActivite;

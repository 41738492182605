import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button/Button';
import MessageField from '~/components/fields/MessageField/MessageField';
import TextField from '~/components/fields/TextField/TextField';
import UploadField from '~/components/fields/UploadField/UploadField';

import './CreateChurchForm.scss';

interface CreateUserFormProps {
  handleSubmit: (values: any) => void;
}

const CreateUserForm: React.FC<CreateUserFormProps> = ({ handleSubmit }) => {
  const validate = Yup.object({
    nom: Yup.string().required('Le nom est requis'),
    description: Yup.string().required('La description est requise'),
    ville: Yup.string().required('La ville est requise'),
    commune: Yup.string().required('La commune est requise'),
    image: Yup.string().required("L'image est requise"),
    longitude: Yup.number().required("La longitude de l'église est requise"),
    latitude: Yup.number().required("La latitude de l'église est requise"),
    pays: Yup.string().required('Le pays est requis'),
    email: Yup.string().email('Adresse email requise'),
    identifiant: Yup.string().required("L'identifiant est requis"),
    password: Yup.string().required('Le mot de passe est requis'),
  });

  /**
   *   nom: church?.nom,
                  description: church?.description,
                  ville: church?.ville,
                  commune: church?.commune,
                  image: church?.image,
                  pays: church?.pays,
                  email: church?.email,
                  identifiant: church?.identifiant,
                  longitude: church?.location?.coordinates[0],
                  latitude: church?.location?.coordinates[1],
   */

  return (
    <div className="createChurchForm">
      <Formik
        validationSchema={validate}
        initialValues={{
          nom: '',
          description: '',
          pays: '',
          email: '',
          identifiant: '',
          ville: '',
          commune: '',
          image: '',
          longitude: '',
          latitude: '',
        }}
        enableReinitialize
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {formik => (
          <Form>
            <Row justify="center">
              <UploadField formProps={formik} name="image" title="ok" />
            </Row>
            <TextField
              name="nom"
              height={35}
              className="text-field-createChurchForm"
              label="Nom de l'église"
              type="text"
              placeholder="Nom de l'église"
            />
            <TextField
              name="password"
              height={35}
              className="text-field-createChurchForm"
              label="Mot de passe"
              type="password"
              placeholder="Mot de passe"
            />
            <MessageField
              name="description"
              className="text-field-createChurchForm"
              label="Description de l'église"
              placeholder="Description de l'église"
              height={100}
            />

            <Row>
              <Col lg={12}>
                <TextField
                  name="pays"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Pays"
                  type="text"
                  placeholder="Pays"
                />
              </Col>
              <Col lg={12}>
                <TextField
                  name="ville"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Ville"
                  type="text"
                  placeholder="Ville"
                />
              </Col>
            </Row>
            <TextField
              name="commune"
              height={35}
              className="text-field-createChurchForm"
              label="Commune"
              type="text"
              placeholder="Commune"
            />

            <Row>
              <Col lg={12}>
                <TextField
                  name="email"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Email"
                  type="email"
                  placeholder="Email"
                />
              </Col>
              <Col lg={12}>
                <TextField
                  name="identifiant"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Identifiant"
                  type="text"
                  placeholder="Identifiant"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <TextField
                  name="longitude"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Longitude"
                  type="number"
                  placeholder="Longitude"
                />
              </Col>
              <Col lg={12}>
                <TextField
                  name="latitude"
                  height={35}
                  className="text-field-createChurchForm"
                  label="Latitude"
                  type="number"
                  placeholder="Latitude"
                />
              </Col>
            </Row>
            <Row justify="center" className="mt-3">
              <Button className="mr-1" type="submit" title="Créer" size="m" rounded color="success" />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateUserForm;

import UploadImageResponse from '~/entities/responses/uploadImageResponse';
import { APIService } from '~/services/ApiService';

export const UploadImageService = {
  async uploadImage(file: any): Promise<UploadImageResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', 'e-diocese-app');
    formData.append('upload_preset', String(process.env.REACT_APP_UPLOAD_PRESET));
    formData.append('api_key', String(process.env.REACT_APP_API_KEY));
    const res = await APIService.call(
      'POST',
      `/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
      formData,
      undefined,
      undefined,
      true,
    );
    return res.data;
  },
  async uploadVideo(file: any): Promise<UploadImageResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', 'e-diocese-app');
    formData.append('upload_preset', String(process.env.REACT_APP_UPLOAD_PRESET));
    formData.append('api_key', String(process.env.REACT_APP_API_KEY));
    const res = await APIService.call(
      'POST',
      `/${process.env.REACT_APP_CLOUD_NAME}/video/upload`,
      formData,
      undefined,
      undefined,
      true,
    );
    return res.data;
  },
};

export const HOME_ROUTE = () => '/';
export const LOGIN_ROUTE = () => '/login';
export const CHURCH_ROUTE = () => '/churchs';
export const CREATE_CHURCH_ROUTE = () => '/churchs/create';
export const ACTIVITE_ROUTE = () => '/activites';
export const CREATE_ACTIVITE_ROUTE = () => '/activites/create';
export const NEWS_ROUTE = () => '/news';
export const CREATE_NEWS_ROUTE = () => '/news/create';
export const VIDEOS_ROUTE = () => '/videos';
export const CREATE_VIDEO_ROUTE = () => '/videos/create';
export const PRIERES_ROUTE = () => '/intentions-prieres';

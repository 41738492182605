import { Col, message, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EyeOutlined, SyncOutlined } from '@ant-design/icons';

import Button from '~/components/Button/Button';
import Card from '~/components/Card/Card';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { PrieresService } from '~/services/PrieresService';
import { Priere as PriereEntitie } from '~/entities/priere';
import Loader from '~/components/Loader/Loader';
import { DATE_FORMAT, formatDate } from '~/utils/date';
import { User } from '~/entities/user';

import './Priere.scss';

const Priere: React.FC = () => {
  const [prieres, setPrieres] = useState<PriereEntitie[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isViewPriereModalVisible, setViewPriereModalVisible] = useState(false);

  const [onlyPriere, setPriere] = useState<PriereEntitie>();
  const [gettingOnlyPriere, setGettingOnlyPriere] = useState(false);

  const getPrieres = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);

      const res = await PrieresService.getPrieres();
      if (res.success === true) {
        const finalPrieres: PriereEntitie[] = [];
        res.intentions.map((e, i) => {
          const alteredPriere = { ...e, customId: i + 1 };
          finalPrieres.push(alteredPriere);
        });
        setLoading(false);
        setPrieres(finalPrieres);
        console.log(finalPrieres);
      } else {
        setLoading(false);
        setPrieres([]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error('Impossible de récupérer la liste des intentions de prières', 3);
    }
  }, []);

  const getOnlyPriere = useCallback(async (id: any) => {
    setGettingOnlyPriere(true);
    try {
      const res = await PrieresService.getPriere(id);
      console.log('only', res);
      if (res.success === true) {
        setPriere(res);
        setGettingOnlyPriere(false);
      }
    } catch (error: any) {
      setGettingOnlyPriere(false);
      message.error("Impossible d'obtenir l'intention de prière", 3);
    }
  }, []);

  const deletePriere = useCallback(async (id: any) => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        key: loadingKey,
        content: 'Suppression en cours',
      });
      const res = await PrieresService.deletePriere(id);
      console.log('status', res);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success("L'intention a bien été supprimée");
        await getPrieres();
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error("Impossible de supprimer l'intention");
    }
  }, []);

  const showViewPriereModal = useCallback(async (id: any) => {
    setViewPriereModalVisible(true);
    await getOnlyPriere(id);
  }, []);

  const handleOkViewPriereModal = useCallback(() => {
    setViewPriereModalVisible(false);
  }, []);

  const handleCancelViewPriereModal = useCallback(() => {
    setViewPriereModalVisible(false);
  }, []);

  const confirmDeletePriereModal = useCallback((messageText: string, id: any) => {
    Modal.confirm({
      content: <div className="tac">{messageText}</div>,
      onOk: async () => await deletePriere(id),
      okText: 'Oui',
      cancelText: 'Annuler',
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'customId',
        sorter: true,
        key: 'customId',
        width: '1%',
      },
      {
        title: 'Temps',
        dataIndex: 'temps',
        sorter: true,
        key: 'temps',
      },
      {
        title: 'But',
        dataIndex: 'but',
        sorter: true,
        key: 'but',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        key: 'description',
      },
      {
        title: 'Utilisateur',
        dataIndex: 'user',
        sorter: true,
        key: 'user',
        render: (user?: User) => <div>{user?.firstName + ' ' + user?.lastName}</div>,
      },
      {
        title: 'Actions',
        children: [
          {
            title: 'Voir',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Afficher l'intention">
                <Button
                  onClick={() => showViewPriereModal(id)}
                  title=""
                  color="success"
                  size="s"
                  rounded
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Supp',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Supprimer l'intention">
                <Button
                  onClick={() => confirmDeletePriereModal('Supprimer cette intention ?', id)}
                  title="x"
                  color="danger"
                  size="s"
                  rounded
                />
              </Tooltip>
            ),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getPrieres();
  }, []);
  return (
    <div className="new">
      {loading ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="new-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Modal
            title={gettingOnlyPriere ? <Loader /> : <div>{"Détails de l'intention de prière"}</div>}
            visible={isViewPriereModalVisible}
            onOk={() => {
              handleOkViewPriereModal();
            }}
            onCancel={handleCancelViewPriereModal}
          >
            {gettingOnlyPriere ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <>
                <Title level={4} weight={FontWeight.MEDIUM} className="tac">
                  Informations sur l'intention de prière
                </Title>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Fidèle: </Text>
                  <Text>{onlyPriere?.user?.firstName + ' ' + onlyPriere?.user?.lastName}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Temps de prière: </Text>
                  <Text>{onlyPriere?.temps}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>But de la prière: </Text>
                  <Text>{onlyPriere?.but}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Description: </Text>
                  <Text>{onlyPriere?.description}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date d'envoie: </Text>
                  <Text>{formatDate(onlyPriere?.createdAt as Date, DATE_FORMAT.DATE_TIME)}</Text>
                </Paragraph>
              </>
            )}
          </Modal>
          <Card slightlyRounded className="new-body-content">
            <Row>
              <Col lg={8}>
                <Title level={4} weight={FontWeight.SEMIBOLD}>
                  Actualités
                </Title>
              </Col>
              <Col lg={3} offset={13} className="tar">
                <Tooltip title="Cliquez pour actualiser la liste">
                  <Button
                    onClick={() => getPrieres()}
                    title="Actualiser"
                    color="success"
                    size="s"
                    rounded
                    icon={<SyncOutlined className="mr-1 mt-1" />}
                  />
                </Tooltip>
              </Col>
            </Row>

            <div className="newTableWrapper">
              <Table bordered size="middle" columns={columns} dataSource={prieres} pagination={{ pageSize: 30 }} />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default Priere;

import { New } from '~/entities/new';
import GeneralResponse from '~/entities/responses/generalResponse';
import GetNewResponse from '~/entities/responses/getNewResponse';
import { APIService } from '~/services/ApiService';

interface CreateNewBody {
  titre: string;
  description: string;
  image: string;
  sousTitre: string;
}

export const NewService = {
  async getNews(): Promise<GetNewResponse> {
    const res = await APIService.call('GET', '/admin/news');
    return res.data;
  },
  async getNew(id: string): Promise<New> {
    const res = await APIService.call('GET', `/admin/news/${id}`);
    return res.data;
  },
  async deleteNew(id: string): Promise<GeneralResponse> {
    const res = await APIService.call('DELETE', `/admin/news/${id}`);
    return res.data;
  },
  async createNew(body: CreateNewBody): Promise<GeneralResponse> {
    const res = await APIService.call('POST', `/admin/news`, body);
    return res.data;
  },
  async putNew(id: string, body: CreateNewBody): Promise<GeneralResponse> {
    const res = await APIService.call('PUT', `/admin/news/${id}`, body);
    return res.data;
  },
};

import { Col, message, Modal, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Title } from '~/components/Typo/Typo';
import Card from '~/components/Card/Card';
import { FontWeight } from '~/theme/constants';
import Loader from '~/components/Loader/Loader';
import Button from '~/components/Button/Button';
import { NewService } from '~/services/NewService';
import CreateNewForm from '~/components/forms/CreateNewForm/CreateNewForm';

import './CreateNew.scss';

const CreateNew: React.FC = () => {
  const { goBack } = useHistory();

  const [creatingNew, setCreatingNew] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Création de l'actualité en cours...");

  const successModal = useCallback((messageText: string) => {
    const seconds = 3;
    const modal = Modal.success({
      content: <div className="tac">{messageText}</div>,
    });
    setTimeout(() => {
      modal.destroy();
    }, seconds * 1000);
  }, []);

  const handleCreateNew = useCallback(async formValues => {
    setCreatingNew(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);
      const res = await NewService.createNew(formValues);
      if (res.success === true) {
        setCreatingNew(false);
        successModal("L'actualité a bien été créée");
        setTimeout(() => {
          goBack();
        }, 3000);
      } else {
        setCreatingNew(false);
      }
    } catch (error: any) {
      setCreatingNew(false);
      message.error(error.response?.data?.statusText || "Impossible de créer l'actualité");
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="createNew">
      <div className="createNew-body-header">
        <Button
          onClick={() => goBack()}
          title="Retour"
          size="s"
          rounded
          color="bluedark"
          type="button"
          icon={<ArrowLeftOutlined className="mt-1 mr-1" />}
          className="mr-3"
        />
      </div>
      {creatingNew ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="createNew-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Col lg={15}>
            <Card slightlyRounded className="createNew-body-content">
              <div className="createNewTableWrapper">
                <Title level={3} weight={FontWeight.SEMIBOLD}>
                  Créer une actualité
                </Title>
                <Row justify="center">
                  <Col lg={20}>
                    <CreateNewForm handleSubmit={handleCreateNew} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CreateNew;

import { Session } from '~/entities/session';
import { LOGIN_ROUTE } from '~/routing/routes';
import { APIService } from '~/services/ApiService';

export const SessionsService = {
  async login(identifiant: string, password: string): Promise<Maybe<Session>> {
    const res = await APIService.call('POST', '/admin/login', { identifiant, password });
    localStorage.setItem('accessToken', res.data.token);
    localStorage.setItem('sessionId', res.data.sessionId);
    localStorage.setItem('isSuperAdmin', res.data.user.isSuperAdmin);
    return res.data;
  },

  async logout(): Promise<void> {
    const sessionId = localStorage.getItem('sessionId');
    await APIService.call('DELETE', `/admin/logout/${sessionId}`);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('isSuperAdmin');
    window.location.href = LOGIN_ROUTE();
  },

  isLoggedIn(): boolean {
    return Boolean(localStorage.getItem('accessToken'));
  },
};

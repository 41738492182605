import { Col, message, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

import Button from '~/components/Button/Button';
import Card from '~/components/Card/Card';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { VideoService } from '~/services/VideoService';
import { Video as VideoEntitie } from '~/entities/video';
import Loader from '~/components/Loader/Loader';
import { DATE_FORMAT, formatDate } from '~/utils/date';
import TextField from '~/components/fields/TextField/TextField';
import MessageField from '~/components/fields/MessageField/MessageField';
import { CREATE_VIDEO_ROUTE } from '~/routing/routes';
import UploadVideo from '~/components/fields/UploadVideo/UploadVideo';

import './Video.scss';

const Video: React.FC = () => {
  const [videos, setVideos] = useState<VideoEntitie[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isViewVideoModalVisible, setViewVideoModalVisible] = useState(false);
  const [isUpdateVideoModalVisible, setUpdateVideoModalVisible] = useState(false);
  const [updatingVideoInfos, setUpdatingVideoInfos] = useState(false);

  const [video, setVideo] = useState<VideoEntitie>();
  const [gettingOnlyVideo, setGettingOnlyVideo] = useState(false);

  const validateUpdateVideo = Yup.object({});

  const { push: redirect } = useHistory();

  const getVideos = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);

      const res = await VideoService.getVideos();
      if (res.success === true) {
        const finalVideos: VideoEntitie[] = [];
        res.videos.map((e, i) => {
          const alteredVideo = { ...e, customId: i + 1 };
          finalVideos.push(alteredVideo);
        });
        setLoading(false);
        setVideos(finalVideos);
      } else {
        setLoading(false);
        setVideos([]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error('Impossible de récupérer la liste des vidéos', 3);
    }
  }, []);

  const getOnlyVideo = useCallback(async (id: any) => {
    setGettingOnlyVideo(true);
    try {
      const res = await VideoService.getVideo(id);
      if (res.success === true) {
        setVideo(res);
        setGettingOnlyVideo(false);
      }
    } catch (error: any) {
      setGettingOnlyVideo(false);
      message.error("Impossible d'obtenir les informations de la vidéo", 3);
    }
  }, []);

  const deleteVideo = useCallback(async (id: any) => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        key: loadingKey,
        content: 'Suppression en cours',
      });
      const res = await VideoService.deleteVideo(id);
      console.log('status', res);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success('La vidéo a bien été supprimée');
        await getVideos();
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error('Impossible de supprimer la vidéo');
    }
  }, []);

  const handleUpdateVideo = useCallback(async (newVideo: VideoEntitie, formValues) => {
    setUpdatingVideoInfos(true);
    const loadingKey = 'loadingMessage';
    try {
      message.loading({
        content: 'Mise à jour en cours',
        key: loadingKey,
      });
      const res = await VideoService.putVideo(newVideo._id as string, formValues);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success('Les informations de ' + formValues.titre + ' ont bien été actualisées', 5);
        setUpdatingVideoInfos(false);
        setUpdateVideoModalVisible(false);
        getVideos();
      }
    } catch (error: any) {
      setUpdatingVideoInfos(false);
      message.destroy(loadingKey);
      message.error('Impossible de mettre à jour les infos de la vidéo', 3);
    }
  }, []);

  const showViewVideoModal = useCallback(async (id: any) => {
    setViewVideoModalVisible(true);
    await getOnlyVideo(id);
  }, []);

  const handleOkViewVideoModal = useCallback(() => {
    setViewVideoModalVisible(false);
  }, []);

  const handleCancelViewVideoModal = useCallback(() => {
    setViewVideoModalVisible(false);
  }, []);

  const showUpdateVideoModal = useCallback(async (id: any) => {
    setUpdateVideoModalVisible(true);
    await getOnlyVideo(id);
  }, []);

  const handleOkUpdateVideoModal = useCallback(() => {
    setUpdateVideoModalVisible(false);
  }, []);

  const handleCancelUpdateVideoModal = useCallback(() => {
    setUpdateVideoModalVisible(false);
  }, []);

  const confirmDeleteVideoModal = useCallback((messageText: string, id: any) => {
    Modal.confirm({
      content: <div className="tac">{messageText}</div>,
      onOk: async () => await deleteVideo(id),
      okText: 'Oui',
      cancelText: 'Annuler',
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'customId',
        sorter: true,
        key: 'customId',
        width: '1%',
      },
      {
        title: 'Nom',
        dataIndex: 'titre',
        sorter: true,
        key: 'titre',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        key: 'description',
      },
      {
        title: 'Image',
        dataIndex: 'url',
        sorter: true,
        key: 'url',
        render: (url: string) => (
          <Row justify="center">
            <video src={url} height={50} width={50} />
          </Row>
        ),
      },
      {
        title: 'Actions',
        children: [
          {
            title: 'Voir',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Afficher la vidéo">
                <Button
                  onClick={() => showViewVideoModal(id)}
                  title=""
                  color="success"
                  size="s"
                  rounded
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Supp',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Supprimer une vidéo">
                <Button
                  onClick={() => confirmDeleteVideoModal('Supprimer cette vidéo ?', id)}
                  title="x"
                  color="danger"
                  size="s"
                  rounded
                />
              </Tooltip>
            ),
          },
          {
            title: 'Up',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Modifier une vidéo">
                <Button
                  onClick={() => showUpdateVideoModal(id)}
                  title=""
                  color="warning"
                  size="s"
                  rounded
                  icon={<EditOutlined />}
                />
              </Tooltip>
            ),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getVideos();
  }, []);
  return (
    <div className="video">
      <Tooltip className="mb-5" title="Cliquez ici pour ajouter une nouvelle vidéo">
        <Button
          title="Ajouter une nouvelle vidéo"
          color="bluedark"
          rounded
          size="s"
          icon={<PlusOutlined className="mt-1 mr-1" />}
          onClick={() => redirect(CREATE_VIDEO_ROUTE())}
        />
      </Tooltip>
      {loading ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="video-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Modal
            title={gettingOnlyVideo ? <Loader /> : <div>{`Détails de l'activité ${video?.titre}`}</div>}
            visible={isViewVideoModalVisible}
            onOk={() => {
              handleOkViewVideoModal();
            }}
            onCancel={handleCancelViewVideoModal}
          >
            {gettingOnlyVideo ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <>
                <Title level={4} weight={FontWeight.MEDIUM} className="tac">
                  Informations sur la vidéo
                </Title>
                <Row justify="center" className="mb-5">
                  <video height={200} width={200} src={video?.url} controls></video>
                </Row>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Titre de la vidéo: </Text>
                  <Text>{video?.titre}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Description de la vidéo: </Text>
                  <Text>{video?.description}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date d'insertion: </Text>
                  <Text>{formatDate(video?.createdAt as Date, DATE_FORMAT.DATE_TIME)}</Text>
                </Paragraph>
              </>
            )}
          </Modal>
          <Modal
            footer
            title={gettingOnlyVideo ? <Loader /> : <div>{`Modifier les informations de la vidéo ${video?.titre}`}</div>}
            visible={isUpdateVideoModalVisible}
            onOk={() => {
              handleOkUpdateVideoModal();
            }}
            onCancel={handleCancelUpdateVideoModal}
          >
            {gettingOnlyVideo ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <Formik
                initialValues={{
                  titre: video?.titre,
                  description: video?.description,
                  url: video?.url,
                }}
                enableReinitialize
                validationSchema={validateUpdateVideo}
                onSubmit={values => handleUpdateVideo(video as VideoEntitie, values)}
              >
                {formik => (
                  <Form>
                    {console.log(formik.values)}

                    <Row justify="center">
                      <UploadVideo formProps={formik} name="url" defaultAvatar={video?.url} title="" />
                    </Row>

                    <TextField
                      disabled={updatingVideoInfos}
                      name="titre"
                      height={35}
                      label="Titre de la vidéo"
                      type="text"
                      placeholder="Titre de la vidéo"
                      className="mb-3"
                    />
                    <MessageField
                      disabled={updatingVideoInfos}
                      name="description"
                      label="Description"
                      placeholder="Description"
                      className="mb-3"
                    />

                    <Row justify="center" className="mt-7">
                      <Button
                        onClick={() => handleCancelUpdateVideoModal()}
                        disabled={updatingVideoInfos}
                        type="button"
                        rounded
                        color="light"
                        title="Annuler"
                        size="m"
                      />
                      <Tooltip title="Cliquez sur le bouton pour modifier les informations de la vidéo">
                        <Button
                          disabled={updatingVideoInfos}
                          type="submit"
                          rounded
                          color="success"
                          title="Enregistrer"
                          size="m"
                        />
                      </Tooltip>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </Modal>
          <Card slightlyRounded className="video-body-content">
            <Row>
              <Col lg={8}>
                <Title level={4} weight={FontWeight.SEMIBOLD}>
                  Vidéos
                </Title>
              </Col>
              <Col lg={3} offset={13} className="tar">
                <Tooltip title="Cliquez pour actualiser la liste">
                  <Button
                    onClick={() => getVideos()}
                    title="Actualiser"
                    color="success"
                    size="s"
                    rounded
                    icon={<SyncOutlined className="mr-1 mt-1" />}
                  />
                </Tooltip>
              </Col>
            </Row>

            <div className="videoTableWrapper">
              <Table bordered size="middle" columns={columns} dataSource={videos} pagination={{ pageSize: 30 }} />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default Video;

import React, { useCallback, useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import cn from 'classnames';
import { message } from 'antd';

import { TextFieldProps } from '~/components/fields/TextField/TextField';
import emptyAvatar from '~/assets/images/avatar.png';
import Image from '~/components/Image/Image';
import { UploadImageService } from '~/services/UploadImageService';
import Loader from '~/components/Loader/Loader';

import './UploadField.scss';

interface UploadFieldProps extends TextFieldProps {
  title?: string;
  formProps?: any;
  defaultAvatar?: string;
  saveNewImageUrl?: (image: string) => Promise<void>;
}

const UploadField: React.FC<UploadFieldProps> = ({
  className,
  name,
  disabled,
  title,
  formProps,
  defaultAvatar,
  saveNewImageUrl,
  ...restParams
}) => {
  const [field, meta] = useField(name);
  const [avatar, setAvatar] = useState(emptyAvatar);
  const [loading, setLoading] = useState(false);

  const uploadAvatar = useCallback(
    async (event: any): Promise<any> => {
      const picture: File = event.target.files[0];
      formProps.setFieldValue(field.name, picture);
      setLoading(true);
      try {
        const res = await UploadImageService.uploadImage(picture);

        if (res.secure_url) {
          setAvatar(res.secure_url);
          setLoading(false);
          if (typeof saveNewImageUrl === 'function') {
            await saveNewImageUrl(res.secure_url);
          }
          return formProps.setFieldValue(field.name, res.secure_url);
        }
      } catch (error) {
        setLoading(false);
        formProps.setFieldValue(field.name, null);
        return message.error('Erreur', 3);
      }
    },
    [message],
  );

  useEffect(() => {
    if (typeof defaultAvatar === 'string') {
      setAvatar(defaultAvatar);
    }
  }, [defaultAvatar]);

  return (
    <div className={cn(className, 'upload-field')}>
      <label htmlFor={field.name} className="upload-field-label">
        {loading ? (
          <Loader />
        ) : (
          <Image image={avatar} height={100} width={100} border="white" circle className="upload-field-avatar" />
        )}
      </label>
      <input
        className={cn({ 'form-control': meta.touched && meta.error }, { 'is-invalid': meta.touched && meta.error })}
        id={field.name}
        {...restParams}
        type="file"
        disabled={disabled}
        onChange={uploadAvatar}
        accept="*"
      />
      <ErrorMessage name={field.name} component="div" className="text-danger" />
    </div>
  );
};

export default UploadField;

import cn from 'classnames';
import React, { HTMLProps } from 'react';

import './Navbar.scss';

interface ItemProps extends HTMLProps<HTMLDivElement> {
  active?: boolean;
}

interface NavbarSubComponents {
  Item: React.FC<ItemProps>;
}

interface NavbarProps extends HTMLProps<HTMLDivElement> {}

const Navbar: React.FC<NavbarProps> & NavbarSubComponents = ({ children, className }) => (
  <div className={cn(className, 'navbar')}>{children}</div>
);

const Item: React.FC<ItemProps> = ({ children, active, className }) => (
  <div className={cn(className, 'navbar-item', { active })}>
    <span>{children}</span>
  </div>
);

Navbar.Item = Item;

export default Navbar;

import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Menu } from 'antd';
import {
  BankOutlined,
  BookOutlined,
  DashboardOutlined,
  DesktopOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  PieChartOutlined,
  PropertySafetyOutlined,
  ReconciliationOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';

import edioceseLogo from '~/assets/images/logo-ediocese.png';
import verifiedAndSecure from '~/assets/images/verified-and-secure.png';
import Image from '~/components/Image/Image';
import { ACTIVITE_ROUTE, CHURCH_ROUTE, HOME_ROUTE, NEWS_ROUTE, PRIERES_ROUTE, VIDEOS_ROUTE } from '~/routing/routes';

import './Sidebar.scss';

interface SidebarProps {
  showSidebar: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ showSidebar }) => {
  const { push: redirect } = useHistory();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const handleGetPermission = useCallback(() => {
    const superAd = localStorage.getItem('isSuperAdmin');
    setIsSuperAdmin(superAd !== null && superAd === 'true');
  }, []);

  useEffect(() => {
    handleGetPermission();
  }, []);

  return (
    <div className={cn('sidebar', { 'hide-sidebar': !showSidebar })}>
      <div className="left-side-sidebar">
        <Image image={edioceseLogo} width={'40%'} height={55} />
      </div>
      <div className="sidebar-menu-section">
        <Menu style={{ width: 240 }} defaultSelectedKeys={['0']} theme="light" mode="inline">
          <Menu.Item
            icon={<DashboardOutlined className="sidebar-icon" />}
            key="0"
            onClick={() => redirect(HOME_ROUTE())}
          >
            Dashboard
          </Menu.Item>
          {isSuperAdmin === true && (
            <Menu.Item icon={<UserOutlined className="sidebar-icon" />} key="1" onClick={() => redirect(HOME_ROUTE())}>
              Utilisateurs
            </Menu.Item>
          )}
          {isSuperAdmin === true && (
            <Menu.Item
              icon={<BankOutlined className="sidebar-icon" />}
              key="2"
              onClick={() => redirect(CHURCH_ROUTE())}
            >
              Églises
            </Menu.Item>
          )}
          <Menu.Item
            icon={<PieChartOutlined className="sidebar-icon" />}
            key="3"
            onClick={() => redirect(ACTIVITE_ROUTE())}
          >
            Activités
          </Menu.Item>
          <Menu.Item icon={<DesktopOutlined className="sidebar-icon" />} key="4" onClick={() => redirect(NEWS_ROUTE())}>
            Actualités
          </Menu.Item>
          <Menu.Item
            icon={<VideoCameraOutlined className="sidebar-icon" />}
            key="5"
            onClick={() => redirect(VIDEOS_ROUTE())}
          >
            Vidéos
          </Menu.Item>
          <Menu.Item
            icon={<ReconciliationOutlined className="sidebar-icon" />}
            key="6"
            onClick={() => redirect(PRIERES_ROUTE())}
          >
            Intentions de prière
          </Menu.Item>
          {isSuperAdmin === true && (
            <Menu.Item
              icon={<PropertySafetyOutlined className="sidebar-icon" />}
              key="7"
              onClick={() => redirect(HOME_ROUTE())}
            >
              Prix intention de prière
            </Menu.Item>
          )}
          {isSuperAdmin === true && (
            <Menu.Item
              icon={<DollarOutlined className="sidebar-icon" />}
              key="8"
              onClick={() => redirect(HOME_ROUTE())}
            >
              Prix des dons
            </Menu.Item>
          )}
          <Menu.Item icon={<BookOutlined className="sidebar-icon" />} key="9" onClick={() => redirect(HOME_ROUTE())}>
            Lectures du jour
          </Menu.Item>
          {isSuperAdmin === true && (
            <Menu.Item
              icon={<MoneyCollectOutlined className="sidebar-icon" />}
              key="10"
              onClick={() => redirect(HOME_ROUTE())}
            >
              Liste des dons
            </Menu.Item>
          )}
        </Menu>
      </div>
      <div className="sidebar-footer">
        <Image image={verifiedAndSecure} width={'70%'} />
      </div>
    </div>
  );
};

export default Sidebar;

import { Col, message, Modal, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined, EyeOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

import Button from '~/components/Button/Button';
import Card from '~/components/Card/Card';
import { Paragraph, Text, Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import { NewService } from '~/services/NewService';
import { New as NewEntitie } from '~/entities/new';
import Loader from '~/components/Loader/Loader';
import Image from '~/components/Image/Image';
import { DATE_FORMAT, formatDate } from '~/utils/date';
import TextField from '~/components/fields/TextField/TextField';
import MessageField from '~/components/fields/MessageField/MessageField';
import UploadField from '~/components/fields/UploadField/UploadField';
import { CREATE_NEWS_ROUTE } from '~/routing/routes';

import './New.scss';

const New: React.FC = () => {
  const [news, setNews] = useState<NewEntitie[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isViewNewModalVisible, setViewNewModalVisible] = useState(false);
  const [isUpdateNewModalVisible, setUpdateNewModalVisible] = useState(false);
  const [updatingNewInfos, setUpdatingNewInfos] = useState(false);

  const [onlyNew, setNew] = useState<NewEntitie>();
  const [gettingOnlyNew, setGettingOnlyNew] = useState(false);

  const validateUpdateNew = Yup.object({});

  const { push: redirect } = useHistory();

  const getNews = useCallback(async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoadingMessage('Patientez quelques instants...');
      }, 1000);

      const res = await NewService.getNews();
      if (res.success === true) {
        const finalNews: NewEntitie[] = [];
        res.news.map((e, i) => {
          const alteredNew = { ...e, customId: i + 1 };
          finalNews.push(alteredNew);
        });
        setLoading(false);
        setNews(finalNews);
      } else {
        setLoading(false);
        setNews([]);
      }
    } catch (error: any) {
      setLoading(false);
      message.error('Impossible de récupérer la liste des actualités', 3);
    }
  }, []);

  const getOnlyNew = useCallback(async (id: any) => {
    setGettingOnlyNew(true);
    try {
      const res = await NewService.getNew(id);
      if (res.success === true) {
        setNew(res);
        setGettingOnlyNew(false);
      }
    } catch (error: any) {
      setGettingOnlyNew(false);
      message.error("Impossible d'obtenir les informations des actualités", 3);
    }
  }, []);

  const deleteNew = useCallback(async (id: any) => {
    const loadingKey = 'loadingKey';
    try {
      message.loading({
        key: loadingKey,
        content: 'Suppression en cours',
      });
      const res = await NewService.deleteNew(id);
      console.log('status', res);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success("L'actualité a bien été supprimée");
        await getNews();
      }
    } catch (error: any) {
      message.destroy(loadingKey);
      message.error("Impossible de supprimer l'actualité");
    }
  }, []);

  const handleUpdateNew = useCallback(async (newNew: NewEntitie, formValues) => {
    setUpdatingNewInfos(true);
    const loadingKey = 'loadingMessage';
    try {
      message.loading({
        content: 'Mise à jour en cours',
        key: loadingKey,
      });
      const res = await NewService.putNew(newNew._id as string, formValues);
      if (res.success === true) {
        message.destroy(loadingKey);
        message.success('Les informations de ' + formValues.titre + ' ont bien été actualisées', 5);
        setUpdatingNewInfos(false);
        setUpdateNewModalVisible(false);
        getNews();
      }
    } catch (error: any) {
      setUpdatingNewInfos(false);
      message.destroy(loadingKey);
      message.error("Impossible de mettre à jour les infos de l'activité", 3);
    }
  }, []);

  const showViewNewModal = useCallback(async (id: any) => {
    setViewNewModalVisible(true);
    await getOnlyNew(id);
  }, []);

  const handleOkViewNewModal = useCallback(() => {
    setViewNewModalVisible(false);
  }, []);

  const handleCancelViewNewModal = useCallback(() => {
    setViewNewModalVisible(false);
  }, []);

  const showUpdateNewModal = useCallback(async (id: any) => {
    setUpdateNewModalVisible(true);
    await getOnlyNew(id);
  }, []);

  const handleOkUpdateNewModal = useCallback(() => {
    setUpdateNewModalVisible(false);
  }, []);

  const handleCancelUpdateNewModal = useCallback(() => {
    setUpdateNewModalVisible(false);
  }, []);

  const confirmDeleteNewModal = useCallback((messageText: string, id: any) => {
    Modal.confirm({
      content: <div className="tac">{messageText}</div>,
      onOk: async () => await deleteNew(id),
      okText: 'Oui',
      cancelText: 'Annuler',
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'customId',
        sorter: true,
        key: 'customId',
        width: '1%',
      },
      {
        title: 'Titre',
        dataIndex: 'titre',
        sorter: true,
        key: 'titre',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        key: 'description',
      },
      {
        title: 'Sous titre',
        dataIndex: 'sousTitre',
        sorter: true,
        key: 'sousTitre',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        sorter: true,
        key: 'image',
        render: (image: string) => (
          <Row justify="center">
            <Image image={image} height={50} width={50} />
          </Row>
        ),
      },
      {
        title: 'Actions',
        children: [
          {
            title: 'Voir',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Afficher l'actualité">
                <Button
                  onClick={() => showViewNewModal(id)}
                  title=""
                  color="success"
                  size="s"
                  rounded
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Supp',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Supprimer l'actualité">
                <Button
                  onClick={() => confirmDeleteNewModal('Supprimer cette actualité ?', id)}
                  title="x"
                  color="danger"
                  size="s"
                  rounded
                />
              </Tooltip>
            ),
          },
          {
            title: 'Up',
            key: '_id',
            dataIndex: '_id',
            width: '1%',
            render: (id: string) => (
              <Tooltip title="Modifier une actualité">
                <Button
                  onClick={() => showUpdateNewModal(id)}
                  title=""
                  color="warning"
                  size="s"
                  rounded
                  icon={<EditOutlined />}
                />
              </Tooltip>
            ),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getNews();
  }, []);
  return (
    <div className="new">
      <Tooltip className="mb-5" title="Cliquez ici pour créer une nouvele actualité">
        <Button
          title="Créer une nouvelle actualité"
          color="bluedark"
          rounded
          size="s"
          icon={<PlusOutlined className="mt-1 mr-1" />}
          onClick={() => redirect(CREATE_NEWS_ROUTE())}
        />
      </Tooltip>
      {loading ? (
        <Row justify="center">
          <Col lg={8}>
            <Card className="new-loader-content" slightlyRounded>
              <p className="tac">{loadingMessage}</p>
              <Loader />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Modal
            title={gettingOnlyNew ? <Loader /> : <div>{`Détails de l'actualité ${onlyNew?.titre}`}</div>}
            visible={isViewNewModalVisible}
            onOk={() => {
              handleOkViewNewModal();
            }}
            onCancel={handleCancelViewNewModal}
          >
            {gettingOnlyNew ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <>
                <Title level={4} weight={FontWeight.MEDIUM} className="tac">
                  Informations sur l'actualité
                </Title>
                <Row justify="center" className="mb-5">
                  <Image image={onlyNew?.image} rounded height={100} width={100} />
                </Row>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Titre de l'actualité: </Text>
                  <Text>{onlyNew?.titre}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Sous titre de l'actualité: </Text>
                  <Text>{onlyNew?.sousTitre}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Description: </Text>
                  <Text>{onlyNew?.description}</Text>
                </Paragraph>
                <Paragraph>
                  <Text weight={FontWeight.BOLD}>Date d'insertion: </Text>
                  <Text>{formatDate(onlyNew?.createdAt as Date, DATE_FORMAT.DATE_TIME)}</Text>
                </Paragraph>
              </>
            )}
          </Modal>
          <Modal
            footer
            title={
              gettingOnlyNew ? <Loader /> : <div>{`Modifier les informations de l'actualité ${onlyNew?.titre}`}</div>
            }
            visible={isUpdateNewModalVisible}
            onOk={() => {
              handleOkUpdateNewModal();
            }}
            onCancel={handleCancelUpdateNewModal}
          >
            {gettingOnlyNew ? (
              <Row justify="center">
                <Loader />
              </Row>
            ) : (
              <Formik
                initialValues={{
                  titre: onlyNew?.titre,
                  sousTitre: onlyNew?.sousTitre,
                  description: onlyNew?.description,
                  image: onlyNew?.image,
                }}
                enableReinitialize
                validationSchema={validateUpdateNew}
                onSubmit={values => handleUpdateNew(onlyNew as NewEntitie, values)}
              >
                {formik => (
                  <Form>
                    {console.log(formik.values)}

                    <Row justify="center">
                      <UploadField formProps={formik} name="image" defaultAvatar={onlyNew?.image} title="" />
                    </Row>

                    <TextField
                      disabled={updatingNewInfos}
                      name="titre"
                      height={35}
                      label="Titre de l'actualité"
                      type="text"
                      placeholder="Titre de l'actualité"
                      className="mb-3"
                    />
                    <MessageField
                      disabled={updatingNewInfos}
                      name="sousTitre"
                      label="Sous-titre"
                      placeholder="Sous-titre"
                      className="mb-3"
                    />
                    <MessageField
                      disabled={updatingNewInfos}
                      name="description"
                      label="Description"
                      placeholder="Description"
                      className="mb-3"
                    />
                    <Row justify="center" className="mt-7">
                      <Button
                        onClick={() => handleCancelUpdateNewModal()}
                        disabled={updatingNewInfos}
                        type="button"
                        rounded
                        color="light"
                        title="Annuler"
                        size="m"
                      />
                      <Tooltip title="Cliquez sur le bouton pour modifier les informations de l'actualité">
                        <Button
                          disabled={updatingNewInfos}
                          type="submit"
                          rounded
                          color="success"
                          title="Enregistrer"
                          size="m"
                        />
                      </Tooltip>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </Modal>
          <Card slightlyRounded className="new-body-content">
            <Row>
              <Col lg={8}>
                <Title level={4} weight={FontWeight.SEMIBOLD}>
                  Actualités
                </Title>
              </Col>
              <Col lg={3} offset={13} className="tar">
                <Tooltip title="Cliquez pour actualiser la liste">
                  <Button
                    onClick={() => getNews()}
                    title="Actualiser"
                    color="success"
                    size="s"
                    rounded
                    icon={<SyncOutlined className="mr-1 mt-1" />}
                  />
                </Tooltip>
              </Col>
            </Row>

            <div className="newTableWrapper">
              <Table bordered size="middle" columns={columns} dataSource={news} pagination={{ pageSize: 30 }} />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default New;

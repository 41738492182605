import { Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button/Button';
import MessageField from '~/components/fields/MessageField/MessageField';
import TextField from '~/components/fields/TextField/TextField';
import UploadField from '~/components/fields/UploadField/UploadField';

import './CreateNewForm.scss';

interface CreateNewFormProps {
  handleSubmit: (values: any) => void;
}

const CreateNewForm: React.FC<CreateNewFormProps> = ({ handleSubmit }) => {
  const validate = Yup.object({
    titre: Yup.string().required('Le nom est requis'),
    description: Yup.string().required('La description est requise'),
    image: Yup.string().required("L'image est requise"),
    sousTitre: Yup.string().required('Le sous titre est requis'),
  });

  return (
    <div className="createNewForm">
      <Formik
        validationSchema={validate}
        initialValues={{
          titre: '',
          description: '',
          image: '',
          sousTitre: '',
        }}
        enableReinitialize
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {formik => (
          <Form>
            <Row justify="center">
              <UploadField formProps={formik} name="image" title="ok" />
            </Row>
            <TextField
              name="titre"
              height={35}
              className="text-field-createNewForm"
              label="Titre de l'actualité"
              type="text"
              placeholder="Titre de l'actualité"
            />
            <MessageField
              name="description"
              className="text-field-createNewForm"
              label="Description de l'actualité"
              placeholder="Description de l'actualité"
              height={100}
            />

            <TextField
              name="sousTitre"
              height={35}
              className="text-field-createNewForm"
              label="Sous titre"
              type="text"
              placeholder="Sous titre"
            />
            <Row justify="center" className="mt-3">
              <Button className="mr-1" type="submit" title="Créer" size="m" rounded color="success" />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateNewForm;

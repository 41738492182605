import { Activite } from '~/entities/activite';
import GeneralResponse from '~/entities/responses/generalResponse';
import GetActivitesResponse from '~/entities/responses/getActivitesResponse';
import { APIService } from '~/services/ApiService';

interface CreateActiviteBody {
  titre: string;
  description: string;
  image: string;
  ville: string;
  commune: string;
  date: Date;
}

export const ActivitesService = {
  async getActivites(): Promise<GetActivitesResponse> {
    const res = await APIService.call('GET', '/admin/activite');
    return res.data;
  },
  async getActivite(id: string): Promise<Activite> {
    const res = await APIService.call('GET', `/admin/activite/${id}`);
    return res.data;
  },
  async deleteActivite(id: string): Promise<GeneralResponse> {
    const res = await APIService.call('DELETE', `/admin/activite/${id}`);
    return res.data;
  },
  async createActivite(body: CreateActiviteBody): Promise<GeneralResponse> {
    const res = await APIService.call('POST', `/admin/activite`, body);
    return res.data;
  },
  async putActivite(id: string, body: CreateActiviteBody): Promise<GeneralResponse> {
    const res = await APIService.call('PUT', `/admin/activite/${id}`, body);
    return res.data;
  },
};

import { Church } from '~/entities/church';
import GeneralResponse from '~/entities/responses/generalResponse';
import GetChurchsResponse from '~/entities/responses/getChurchsResponse';
import { APIService } from '~/services/ApiService';

interface CreateChurchBody {
  nom: string;
  description?: string;
  ville: string;
  commune: string;
  image: string;
  location: Location;
  identifiant: string;
  pays: string;
  email: string;
  password: string;
}

export interface Location {
  coordinates: number[];
  type: string;
}

export const ChurchsService = {
  async getChurchs(): Promise<GetChurchsResponse> {
    const res = await APIService.call('GET', '/admin/church');
    return res.data;
  },
  async getChurch(id: string): Promise<Church> {
    const res = await APIService.call('GET', `/admin/church/${id}`);
    return res.data;
  },
  async deleteChurch(id: string): Promise<GeneralResponse> {
    const res = await APIService.call('DELETE', `/admin/church/${id}`);
    return res.data;
  },
  async createChurch(body: CreateChurchBody): Promise<GeneralResponse> {
    const res = await APIService.call('POST', `/admin/church`, body);
    return res.data;
  },
  async putChurch(id: string, body: CreateChurchBody): Promise<GeneralResponse> {
    const res = await APIService.call('PUT', `/admin/church/${id}`, body);
    return res.data;
  },
};

/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { CaretDownFilled } from '@ant-design/icons';

import Sidebar from '~/components/Sidebar/Sidebar';
import Content from '~/containers/Content/Content';
import Header from '~/containers/Header/Header';
import Login from '~/pages/login/Login';
import PrivateRoute from '~/routing/PrivateRoute';
import Home from '~/pages/home/Home';
import { SessionsService } from '~/services/SessionsService';
import { User } from '~/entities/user';
import { UsersService } from '~/services/UsersService';
import Loader from '~/components/Loader/Loader';
import {
  ACTIVITE_ROUTE,
  CHURCH_ROUTE,
  CREATE_ACTIVITE_ROUTE,
  CREATE_CHURCH_ROUTE,
  CREATE_NEWS_ROUTE,
  CREATE_VIDEO_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  NEWS_ROUTE,
  PRIERES_ROUTE,
  VIDEOS_ROUTE,
} from '~/routing/routes';
import { LoadingContext } from '~/contexts/LoaderContext';
import Church from '~/pages/church/Church';
import CreateChurch from '~/pages/church/createChurch/CreateChurch';
import Activite from '~/pages/activite/Activite';
import CreateActivite from '~/pages/activite/createActivite/CreateActivite';
import New from '~/pages/new/New';
import CreateNew from '~/pages/new/createNew/CreateNew';
import Video from '~/pages/video/Video';
import CreateVideo from '~/pages/video/createVideo/CreateVideo';
import Priere from '~/pages/priere/Priere';

import './Router.scss';

const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={LOGIN_ROUTE()} component={Login} />
      <Route path="/" component={RoutesWithLayout} />
      <RoutesWithLayout />
    </Switch>
  </BrowserRouter>
);

const RoutesWithLayout: React.FC = () => {
  const [user, setUser] = useState<User>();
  const [gettingUserInfos, setGetUserInfos] = useState(false);

  const { startLoading } = useContext(LoadingContext);

  const handleLogout = useCallback(async () => {
    startLoading();
    await SessionsService.logout();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleLogout()}>{'Déconnexion'}</Menu.Item>
    </Menu>
  );
  const location = useLocation();
  const menuItems = useMemo(
    () =>
      [
        [<div></div>],
        [
          <div className="d-flex">
            <>
              {gettingUserInfos ? (
                <Loader />
              ) : (
                <>
                  <span>{user?.isSuperAdmin ? `${user?.firstName} ${user?.lastName} ` : `${user?.nom}`}</span>
                </>
              )}
            </>
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <CaretDownFilled className="dropdown-image" />
            </Dropdown>
          </div>,
        ],
      ].map(([item], idx) => ({
        key: idx,
        item,
      })),
    [location.pathname, user, gettingUserInfos],
  );

  const [showSidebar, setSidebarShow] = useState(true);

  const handleShowSidebar = useCallback(() => {
    showSidebar ? setSidebarShow(false) : setSidebarShow(true);
  }, [showSidebar]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const checkResponsiveness = useCallback(() => {
    if (isMobile) {
      setSidebarShow(false);
    }
  }, [isMobile]);

  const getAuthenticatedUser = useCallback(async () => {
    setGetUserInfos(true);
    try {
      const res = await UsersService.getUser();
      console.log(res);
      if (res.success) {
        setGetUserInfos(false);
        setUser(res.user);
      }
    } catch (error: any) {
      setGetUserInfos(false);
      console.error("Impossible de récupérer les informations de l'administrateur", error);
    }
  }, []);

  useEffect(() => {
    checkResponsiveness();
    getAuthenticatedUser();
  }, []);

  return (
    <Route
      path="/"
      render={() => (
        <>
          <div className="custom-layout">
            <Sidebar showSidebar={showSidebar} />
            <div className={cn('custom-page-content', { 'set-mr': showSidebar })}>
              <Header items={menuItems} onClick={() => handleShowSidebar()} showSidebar={showSidebar} />
              <Content>
                <Switch>
                  <PrivateRoute exact path={HOME_ROUTE()} component={Home} />
                  <PrivateRoute exact path={CHURCH_ROUTE()} component={Church} />
                  <PrivateRoute exact path={CREATE_CHURCH_ROUTE()} component={CreateChurch} />
                  <PrivateRoute exact path={ACTIVITE_ROUTE()} component={Activite} />
                  <PrivateRoute exact path={CREATE_ACTIVITE_ROUTE()} component={CreateActivite} />
                  <PrivateRoute exact path={NEWS_ROUTE()} component={New} />
                  <PrivateRoute exact path={CREATE_NEWS_ROUTE()} component={CreateNew} />
                  <PrivateRoute exact path={VIDEOS_ROUTE()} component={Video} />
                  <PrivateRoute exact path={CREATE_VIDEO_ROUTE()} component={CreateVideo} />
                  <PrivateRoute exact path={PRIERES_ROUTE()} component={Priere} />
                </Switch>
              </Content>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default Router;

import { Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button/Button';
import MessageField from '~/components/fields/MessageField/MessageField';
import TextField from '~/components/fields/TextField/TextField';
import UploadField from '~/components/fields/UploadField/UploadField';

import './CreateActiviteForm.scss';

interface CreateActiviteFormProps {
  handleSubmit: (values: any) => void;
}

const CreateActiviteForm: React.FC<CreateActiviteFormProps> = ({ handleSubmit }) => {
  const validate = Yup.object({
    titre: Yup.string().required('Le nom est requis'),
    description: Yup.string().required('La description est requise'),
    ville: Yup.string().required('La ville est requise'),
    commune: Yup.string().required('La commune est requise'),
    image: Yup.string().required("L'image est requise"),
    date: Yup.date().required("La date de l'activité est requise"),
  });

  return (
    <div className="createActiviteForm">
      <Formik
        validationSchema={validate}
        initialValues={{
          titre: '',
          description: '',
          ville: '',
          commune: '',
          image: '',
          date: '',
        }}
        enableReinitialize
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {formik => (
          <Form>
            <Row justify="center">
              <UploadField formProps={formik} name="image" title="ok" />
            </Row>
            <TextField
              name="titre"
              height={35}
              className="text-field-createActiviteForm"
              label="Nom de l'activité"
              type="text"
              placeholder="Nom de l'activité"
            />
            <MessageField
              name="description"
              className="text-field-createActiviteForm"
              label="Description de l'activité"
              placeholder="Description de l'activité"
              height={100}
            />

            <TextField
              name="ville"
              height={35}
              className="text-field-createActiviteForm"
              label="Ville"
              type="text"
              placeholder="Ville"
            />
            <TextField
              name="commune"
              height={35}
              className="text-field-createActiviteForm"
              label="Commune"
              type="text"
              placeholder="Commune"
            />
            <TextField
              name="date"
              height={35}
              className="text-field-createActiviteForm"
              label="Date de l'activité"
              type="date"
              placeholder="Date de l'activité"
            />
            <Row justify="center" className="mt-3">
              <Button className="mr-1" type="submit" title="Créer" size="m" rounded color="success" />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateActiviteForm;

import { Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button/Button';
import MessageField from '~/components/fields/MessageField/MessageField';
import TextField from '~/components/fields/TextField/TextField';
import UploadVideo from '~/components/fields/UploadVideo/UploadVideo';

import './CreateVideoForm.scss';

interface CreateVideoFormProps {
  handleSubmit: (values: any) => void;
}

const CreateVideoForm: React.FC<CreateVideoFormProps> = ({ handleSubmit }) => {
  const validate = Yup.object({
    titre: Yup.string().required('Le titre de la vidéo est requis'),
    description: Yup.string().required('La description est requise'),
    url: Yup.string().required('La vidéo est requise'),
  });

  return (
    <div className="createVideoForm">
      <Formik
        validationSchema={validate}
        initialValues={{
          titre: '',
          description: '',
          url: '',
        }}
        enableReinitialize
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {formik => (
          <Form>
            <Row justify="center">
              <UploadVideo formProps={formik} name="url" title="ok" />
            </Row>
            <TextField
              name="titre"
              height={35}
              className="text-field-createVideoForm"
              label="Nom de la vidéo"
              type="text"
              placeholder="Nom de la vidéo"
            />
            <MessageField
              name="description"
              className="text-field-createVideoForm"
              label="Description de la vidéo"
              placeholder="Description de la vidéo"
              height={100}
            />

            <Row justify="center" className="mt-3">
              <Button className="mr-1" type="submit" title="Ajouter" size="m" rounded color="success" />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateVideoForm;

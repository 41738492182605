import { Col, Row } from 'antd';
import React, { HTMLProps } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Image from '~/components/Image/Image';
import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';
import layer from '~/assets/images/login-layer.png';
import TextField from '~/components/fields/TextField/TextField';
import Button from '~/components/Button/Button';
import ediocese from '~/assets/images/logo-ediocese.png';

import './LoginForm.scss';

export interface LoginFormProps extends HTMLProps<HTMLDivElement> {
  handleSubmit: (values: any) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleSubmit, ...restParams }) => {
  const validate = Yup.object({
    identifiant: Yup.string().required('Identifiant requis'),
    password: Yup.string().required('Le mot de passe est requis'),
  });

  return (
    <div {...restParams} className="login-form">
      <Row justify="center">
        <Col lg={12} sm={24} xs={24}>
          <div className="login-form-wrapper">
            <Row justify="center">
              <Image image={ediocese} />
            </Row>
            <Row justify="center">
              <Title className="login-form-title" level={1} weight={FontWeight.BOLD}>
                Bienvenue, <br />
                Connectez-vous.
              </Title>
            </Row>
            <div className="login-form-wrapper-footer">
              <Row justify="center">
                <img src={layer} width={'70%'} />
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>
          <div className="login-form-wrapper-content">
            <Formik
              onSubmit={values => {
                handleSubmit(values);
              }}
              initialValues={{ identifiant: '', password: '' }}
              validationSchema={validate}
            >
              <Form className="login-form-self">
                <TextField
                  height={50}
                  className="text-field-login-form"
                  label={'Identifiant'}
                  name="identifiant"
                  type="text"
                  placeholder={'Identifiant'}
                />
                <TextField
                  height={50}
                  className="text-field-login-form"
                  label={'Mot de passe'}
                  name="password"
                  type="password"
                  placeholder={'Mot de passe'}
                />
                <Row justify="center" className="login-form-button-wrapper mt-5">
                  <Button
                    className="login-form-button"
                    type="submit"
                    title={'Connexion'}
                    size="m"
                    color="bluedark"
                    rounded
                  />
                </Row>
              </Form>
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;

import React from 'react';

import { Title } from '~/components/Typo/Typo';
import { FontWeight } from '~/theme/constants';

import './HeaderTitle.scss';

const HeaderTitle: React.FC = () => (
  <Title className="header-title" level={3} weight={FontWeight.LIGHT}>
    {'Administration'}
  </Title>
);

export default HeaderTitle;

import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
// import querystring from 'querystring';

export const APIService = {
  call(
    method: Method,
    path: string,
    body?: Record<string, any>,
    query?: Record<string, any> | string,
    headers?: Record<string, any>,
    isFile: boolean = false,
    isGuest?: boolean,
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const accessToken = localStorage.getItem('accessToken');
      const currentLanguage = localStorage.getItem('i18nextLng');

      let url = isFile ? process.env.REACT_APP_CLOUDINARY_UPLOAD_URL + path : process.env.REACT_APP_API_URL + path;

      if (query) {
        url += query;
        // url += typeof query === 'string' ? query : '?' + querystring.stringify(query);
      }

      const options = {
        method,
        url,
        headers: headers || {},
      } as AxiosRequestConfig;

      if (accessToken && !isFile && !isGuest) {
        options.headers.authorization = `Bearer ${accessToken}`;
      }

      if (currentLanguage && !isFile) {
        options.headers['Accept-Language'] = currentLanguage;
      }

      if (body) {
        options.data = body;
      }

      axios(options)
        .then(resolve)
        .catch(e => {
          if (e.response?.status === 401) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('sessionId');
            window.location.href = '/login';
          } else {
            return reject(e);
          }
        });
    });
  },
};

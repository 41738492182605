import React, { ReactElement } from 'react';

import Navbar from '~/components/Navbar/Navbar';

import './NavigationBar.scss';

interface Item {
  active?: boolean;
  item?: ReactElement;
}

export interface NavigationBarProps {
  items: Item[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ items }) => (
  <Navbar>
    {items.map(({ active, item }, key) => (
      <Navbar.Item key={key} active={active}>
        {item}
      </Navbar.Item>
    ))}
  </Navbar>
);

export default NavigationBar;
